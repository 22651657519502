.capitalize {
  text-transform: capitalize;
}

img {
  max-width: 100%;
}

#ticket-filter-row {
  padding: 0 !important;
}

.ui.form .fields .field.filter-dropdown {
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
}

#ticket-input-form {
  margin: 30px auto;
  width: 800px;
}

#ticket-details-segment,
#ticket-comments-segment {
  margin: 30px auto;
  width: 800px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow:  20px 20px 60px #d9d9d9, 
             -20px -20px 60px #ffffff;
             border:none !important;

}
#ticket-header{
  display: flex;
  justify-content: space-between;
  padding:20px;
}
#ticket-options-dd {
  margin-left: 5px;
}

#ticket-options-dd > i {
  margin-top: 0;
}

#ticket-description {
  white-space: pre-wrap;
  padding:20px
}

#ticket-attachments-header,
.comment-attachments-header {
  padding-top: 30px; 
  padding-bottom: 30px;
}

#ticket-attachments-header {
  margin-left: 18px;
  margin-right: 18px;
}

#ticket-attachments-subheader,
.comment-attachments-subheader {
  margin-top: 20px;
}

#ticket-attachment-list,
#comment-attachment-list {
  margin-top: 40px;
  margin-bottom: 40px;
}

.comment-segment {
  white-space: pre-wrap;
  padding:20px;
  background: #f4f2ef;
  border-radius: 20px;
  margin-top:5px;
}

#ticket-input-form textarea {
  font-size: 16px;
}

#ticket-input-form .ui.input {
  font-size: 16px;
}
#ticket-input-form .selection {
  font-size: 16px;
}

#ticket-list-main-col table thead tr th {
  position: sticky;
  top: 64px;
} 

#ticket-load-error-message {
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.ui.table thead th.sortableCol {
  cursor: pointer;
}

i.icon.edit-comment-button {
  margin-left: 7px;
}

i.icon.edit-comment-button:hover {
  cursor: pointer;
  color: #FF5630;
}

.ui.six.statistics .statistic.status-filter:hover,
.ui.six.statistics .statistic.status-filter.selected {
  cursor: pointer;
border-bottom: 2px solid #FF5630;
}

.ticket-table{
border-collapse: separate !important;
border-spacing: 0 5px !important;
background-color:#eff1f5 !important;
border:none !important;

}
.ticket-table thead{
  
} 
.ticket-table tr{
  margin-top:20px !important;
  
}
.ticket-table > table, td {
  background-color:white !important;
}
#ticket-list-main-col{
  padding-left: 80px;
  padding-right: 80px;
}
#ticket-list-main-col > table > tbody > tr > td > i{
min-height: 30px !important;
margin-top:12px;
}
.upload-container{
  border:2px dashed #a9a9a9;
  background:none !important;
  box-shadow:none !important;
  color:#a9a9a9 !important;
}


.filter-bar{
  background-color:#ffffff !important;
  margin-top:-30px !important;
  min-height: 100vh;
}
.ticket-statistics{
  border-radius: 30px;
background: #ffffff;
box-shadow:  10px 10px 20px #c9c9c9, 
             -10px -10px 20px #ffffff;
             margin-bottom:20px !important;
             padding-top: 18px;
             padding-bottom: 10px;
}
.filter-bar button{
  margin-bottom: 3px !important;

}

.ticket-meta{
  
  margin: 18px;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #e3e6f0;
}