#first-login-form {
  margin: 30px auto;
  width: 1000px;
}

#password-error-message {
  width: 50%;
  text-align: center;
}

.ui.form .field.centered-form-element,
.ui.form .message.centered-form-element,
.ui.form .ui.button.centered-form-element {
  margin-right: auto;
  margin-left: auto;
}

.ui.form .ui.button.centered-form-element {
  width: 50%;
  display: block;
}