.product-card {
  border-radius: 12px !important;
  padding: 20px !important;
  background: #ffffff !important;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff !important;

}

.product-card:hover {
  box-shadow: 11px 11px 22px #dedede,
    -11px -11px 22px #ffffff !important;

}

.ui.cards>.card>.content>.header.product-header {
  display: flex;
  justify-content: flex-start;
}

.product-header-link {
  color: #000000de;
  margin-top: 10px;
  margin-bottom: 10px;
}

.product-header-link:hover {
  color: #0548a6;
}

.ui.message.no-product-message {
  width: 200px;
  height: 50px;
  margin-top: 30px !important;
}

#languageDD {
  margin-left: 10px;
}

#collection-list:hover {
  margin-right: -10px;
}

#collection-list {
  position: fixed;
  width: calc(25% - 30px);
  height: calc(100vh - 70px);
  overflow-y: scroll;
  padding: 16px 16px;
  background-color: #f1f1f1;
  border-right: 1px solid #adadad;
  margin-top: -14px;
  margin-left: -16px;
}

.collection-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 20px;
}

.article-list-item {
  color: #000;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
}

.article-list-item:hover {
  border-left: 2px solid #FF5630;
  margin-left: 8px !important;
}

.article-list-item-active {
  border-left: 2px solid #FF5630;
  margin-left: 8px !important;
  background-color: rgba(232, 232, 232, 0.719);
}

.article-menu-subheader-list {
  list-style-type: none;
  margin: 5px 0 5px 8px !important;
  padding: 0 5px 0 15px !important;
}

.article-menu-subheader {
  cursor: pointer;
  font-size: 0.95em;
  padding-top: 4px;
  padding-bottom: 4px;
}

.article-menu-subheader:hover {
  background-color: rgba(232, 232, 232, 0.719);
}

#article-viewer {
  max-width: 740px;
  margin: 0 auto;
}

.ql-editor p {
  font-size: 16px !important;
}

#article-title-input {
  margin-bottom: 25px;
  margin-top: 6px;
  font-size: 30px !important;
  margin-left: 10px !important;
}

#article-title-header {
  margin-bottom: 25px;
  margin-top: 6px;
  font-size: 30px !important;
}

/* Edit mode only */

#product-description-textarea {
  margin-top: 10px;
  margin-bottom: 10px;
  resize: vertical;
}

.ui.compact.item.dropdown>i.icon {
  margin-top: 10px;
  margin-left: 5px;
}

#article-editor-quill .ql-editor {
  height: calc(100vh - 290px) !important;
}

.ql-toolbar {
  border: none !important;
  margin-bottom: 30px;
}

.ql-container {
  border: none !important;
}

.saved {
  opacity: 0;
  overflow: hidden;
  transition: all .3s ease-in-out;
  border-radius: 3px;
}

.saved-visible {
  opacity: 1;
}

.article-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 24px;
  color: #7d7d7e;
}

#article-status-dd {
  width: 90px;
}

.ql-snow .ql-editor img {
  -webkit-box-shadow: 0px 0px 5px 2px rgba(199, 199, 199, 1);
  -moz-box-shadow: 0px 0px 5px 2px rgba(199, 199, 199, 1);
  box-shadow: 0px 0px 5px 2px rgba(199, 199, 199, 1);
}

#doc-editor-page {
  margin-bottom: 0;
}

#add-collection-button {
  margin-bottom: 12px;
}

#version-options-dd {
  margin-left: 10px;
  margin-right: 20px;
}

#version-options-dd>i {
  margin-top: 0;
}

.collection-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 20px;
}

.collection-menu-item i {
  cursor: pointer;
  color: #6d6d6d;
  padding: 3px 18px 20px 5px;
  border-radius: 90px;
}

.collection-menu-item i:hover,
.article-menu-item i:hover {
  color: #df4e19;
}

.hide {
  opacity: 0;
}

.hide:hover {
  opacity: 1;
}

i.icon.article-status-icon {
  margin-left: 5px;
}

#remove-article-icon {
  cursor: pointer;
}

#article-title-span {
  cursor: pointer;
}

.article-title-link {
  color: #000;
}

.article-title-link:hover {
  color: #000;
}

.article-menu-item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
}

.article-menu-item:hover {
  border-left: 2px solid #FF5630;
  margin-left: 8px !important;
}

.article-menu-item-active {
  border-left: 2px solid #FF5630;
  margin-left: 8px !important;
  background-color: rgba(232, 232, 232, 0.719);
}

.article-menu-subheader-list {
  list-style-type: none;
  margin: 5px 0 5px 8px !important;
  padding: 0 5px 0 15px !important;
}

.article-menu-subheader {
  cursor: pointer;
  font-size: 0.95em;
  padding-top: 4px;
  padding-bottom: 4px;
}

.article-menu-subheader:hover {
  background-color: rgba(232, 232, 232, 0.719);
}

.saved {
  position: fixed;
  bottom: 20px;
  z-index: 9;
}

.image-tool__image-picture {
  margin-right: auto;
  margin-left: auto;
}

.image-tool__caption {
  text-align: center;
  border: none;
  box-shadow: none;
  padding-top: 0px;
  font-style: italic;
}

#article-viewer .cdx-block.image-tool.image-tool--filled {
  margin-top: 20px;
  margin-bottom: 20px;
}

#article-viewer .code-block {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  color: #41314e;
  line-height: 1.6em;
  font-size: 12px;
  background: #f8f7fa;
  border: 1px solid #f1f1f4;
  box-shadow: none;
  padding: 10px 12px;
  overflow: auto;
}

.cdx-block {
  padding: .4em 0;
}

#editorjs .tc-table__cell {
  text-align: center;
}

#editorjs .tc-table tr:first-child {
  font-weight: bold;
}

#article-viewer .tc-table__cell {
  padding: 10px;
  text-align: center;
}