/* NavBar.js START */

.side-bar-u {
  padding-top: 14px;
  background-color: #0548a6;
  height: 100%;
  position: fixed;
  width: 60px;
  top: 0px;
  z-index: 9;
}

.sb-logo {
  padding-bottom: 26px;
  border-bottom: 1px solid #2161c0;
}

.sb-logo img {
  width: 40px;
  margin-left: 9px;
}

.ui.inverted.popup.popover {
  border-radius: 2px;
  opacity: 1;
  padding: 10px;
  background-color: #FFAB00;
}

.sb-menu-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sb-menu-item {
  margin: 10px;
  cursor: pointer;
  color: #ffffff;
}

.sb-menu-item :hover {
  color: #ffab00;
  transition: 0.3s;
}

.sb-menu-item-active {
  color: #ffab00;
}

.to-bottom {
  position: absolute;
  bottom: 0;
  left: 0px;
  background-color: #0545a5;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 60px;
}

/* NavBar.js END */


/* PageHeader.js START */

#page-header {
  z-index: 8;
  background-color: white;
  padding: 10px 0px 10px 20px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  position: fixed;
}

.page-title {
  display: flex;
}

.page-icon {
  background-color: #FF5630;
  display: flex;
  margin-right: 10px;
  width: 40px;
  border-radius: 4px;
  padding-top: 7px;
}

#page-header .page-icon i.icon:only-child {
  color: white;
  font-size: 18px;
  margin: 0px auto;

}

#page-header>.column {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.page-actions {
  align-items: center;
  display: flex;
  padding-right: 16px;
}

/* PageHeader.js END */