#login-grid {
  height: 100vh;
}

#login-grid>.column {
  max-width: 450px;
}

#visium-logo {
  height: 80px;
  left: -20px;
}

#login-form {
  padding-top: 20px;
  padding-bottom: 20px;
}

#netas-logo {
  height: 20px;
  margin-top: 20px;
}