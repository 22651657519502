body {
  background-color: #eff1f5;
}

.page-content {
  margin-top: 65px;
  margin-bottom: 50px;
}

#main-app-container {
  margin-top: 1em;
  padding-left: 15px;
  padding-right: 15px;
}
.main-menu-item{
  margin-bottom: 20px;  
  border-radius: 24px;
  padding:30px !important;
background: #ffffff;
box-shadow:  38px 38px 77px #d9d9d9, 
             -38px -38px 77px #ffffff ;

}
.main-menu-item:hover{
  box-shadow:  18px 38px 77px #e9e9e9, 
  -38px -38px 77px #ffffff;
}

.ui.basic.active.button, .ui.basic.buttons .active.button {
  background-color:#f54a23 !important;
  border-radius: 20px !important;
  color:white !important;
}
.main-menu-container{
display: flex;
justify-content:space-between;
}
